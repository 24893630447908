import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { Box, BoxProps, Breakpoint, Theme } from '@mui/material'
import { ColorTheme, TextSize } from '@styles/theme/types'

export type TextWeight = 'light' | 'regular' | 'semibold' | 'bold'

export type TextColor = keyof ColorTheme

export type TextProps = {
  size?: TextSize | { [key in Breakpoint]?: TextSize }
  weight?: TextWeight
  color?: TextColor
  opacity?: number
}

const fontWeights: { [key in TextWeight]: number } = {
  light: 300,
  regular: 400,
  semibold: 500,
  bold: 700
}

const getFontSizeCss = (size: TextProps['size'] = 'size-14', theme: Theme): FlattenSimpleInterpolation => {
  if (typeof size === 'string') {
    return css`
      font-size: ${theme.global.typography.size[size]};
    `
  }

  return Object.entries(size).map(
    ([breakpoint, textSize]) => css`
      ${theme.breakpoints.up(breakpoint as Breakpoint)} {
        font-size: ${theme.global.typography.size[textSize]};
      }
    `
  )
}

// TODO: Rename to TextSpan (to avoid import conflicts)
export const Text = styled((props: Omit<BoxProps, keyof TextProps>) => (
  <Box {...props} component={props.component ?? 'span'} />
))<TextProps>(
  ({ theme, size, weight = 'light', color, opacity = 1 }) => css`
    ${getFontSizeCss(size, theme)}

    font-weight: ${fontWeights[weight]};
    color: ${color ? theme.colors[color] : 'inherit'};
    opacity: ${opacity};
  `
)
